var onmailTheme = {
  onmailPrimaryColor: "#005FED",
  onmailSecondaryColor: "#0A3ED8",
  onmailWhite: "#ffffff",
  onmailBlack: "#222326",
  onmailExtraDarkGrey: "#454b5b",
  onmailDarkGrey: "#727982",
  onmailMediumGrey: "#8e99a3",
  onmailLightGrey: "#d8dbdc",
  onmailExtraLightGrey: "#f5f5f5",
};

export const OnmailColors = {
  onmailPrimaryColor: onmailTheme.onmailPrimaryColor,
  onmailSecondaryColor: onmailTheme.onmailSecondaryColor,
  onmailWhite: onmailTheme.onmailWhite,
  onmailBlack: onmailTheme.onmailBlack,
  onmailExtraDarkGrey: onmailTheme.onmailExtraDarkGrey,
  onmailDarkGrey: onmailTheme.onmailDarkGrey,
  onmailMediumGrey: onmailTheme.onmailMediumGrey,
  onmailLightGrey: onmailTheme.onmailLightGrey,
  onmailExtraLightGrey: onmailTheme.onmailExtraLightGrey,
};

var theme = {
  primaryBackground: '#fff',
  cardColor: '#fff',
  secondaryBackground: '#f9f9f9',
  primaryColor: '#0062e7',
  primaryTextColor: '#4e4e4e',
  secondaryTextColor: '#888888',
  orangeColor: '#FD9326',
  purpleColor: '#5C3BFA',
};

export const Colors = {
  primaryBackground: theme.primaryBackground,
  secondaryBackground: theme.secondaryBackground,
  primaryColor: theme.primaryColor,
  primaryTextColor: theme.primaryTextColor,
  secondaryTextColor: theme.secondaryTextColor,
  black: '#000',
  white: '#fff',
  orangeColor: theme.orangeColor,
  purpleColor: theme.purpleColor,
};

if (localStorage.getItem('theme') === 'dark') {
  theme = {
    primaryBackground: '#000',
    secondaryBackground: '#222',
    cardColor: '#333',
    primaryColor: '#0062e7',
    primaryTextColor: '#fff',
    secondaryTextColor: '#ccc',
  };
}

export const Theme = {
  cardColor: theme.cardColor,
  primaryBackground: theme.primaryBackground,
  secondaryBackground: theme.secondaryBackground,
  primaryColor: theme.primaryColor,
  primaryTextColor: theme.primaryTextColor,
  secondaryTextColor: theme.secondaryTextColor,
};

export const Values = {
  globalRadius: '40px',
};

export const HeaderHeight = 80;
export const MobileHeaderHeight = 80;

export const WebServerApiKey = 'bdH0VGExAEIhPq0z5vwdyVuHVzWx0hcR';
export const WebServerRoot = 'https://web-marketing.edison.tech/';

export const Products = [
  { value: 'mailIos', label: 'Edison Mail iOS' },
  { value: 'mailAndroid', label: 'Edison Mail Android' },
  { value: 'mailDesktop', label: 'Edison Mail Desktop' },
  { value: 'edisonTrends', label: 'Edison Trends' },
  { value: 'edisonBiz', label: 'Edison Business' },
  { value: 'pressReleases', label: 'Press Releases' },
  { value: 'onMail', label: 'OnMail' },
  { value: 'blog', label: 'Blog' },
];

export const NewsRoomOptions = [
  { value: ['mailIos', 'mailAndroid', 'mailDesktop'], label: 'Edison Mail' },
  { value: ['edisonTrends'], label: 'Edison Trends' },
  { value: ['onMail'], label: 'OnMail' },
];

export const BlogOptions = [
  { value: 'Tips & Tricks', label: 'Tips & Tricks' },
  { value: 'OnMail', label: 'OnMail' },
  { value: 'Edison Mail', label: 'Edison Mail' },
  { value: 'Email Matters', label: 'Email Matters' }
];

export const LatestDownloadableMacFile = 'https://apps.apple.com/app/id1489591003';
export const LatestDownloadableAndroidLink = 'https://play.google.com/store/apps/details?id=com.easilydo.mail';
export const LatestDownloadableIosLink = 'https://apps.apple.com/us/app/email-edison-mail/id922793622';
export const LatestDownloadableOnmailIosLink = 'https://apps.apple.com/us/app/onmail-fast-simple-email/id1531381193';
export const LatestDownloadableOnmailAndroidLink = 'https://play.google.com/store/apps/details?id=com.easilydo.onmail';
export const FrLink = 'https://mail.onmail.com/signup';
export const SignUpLink = FrLink
