import React, { Component } from 'react';
import "./onMailHeader.scss";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import {Link, Redirect} from 'react-router-dom';
import {OnmailColors,SignUpLink, FrLink} from './../../utils/constants';
import MaterialIcon from 'material-icons-react';
import OnmailMenu from './onmailMenu';
import { ReportFirebaseEvent } from './../../utils/analytics';

class OnMailHeader extends Component {
  constructor(props) {
      super(props);
      this.state = {
        active:false,
        menuActive:false
      }
  }

  toggleMenu = () =>{
    if(this.state.menuActive){
      this.setState({
        menuActive:false
      })
    } else{
      this.setState({
        menuActive:true
      })
    }
  }

  trackExit = async(action) =>{
    let link = '/';
    if(action == "login"){
      link = 'https://mail.onmail.com/login'
      await ReportFirebaseEvent('onboard_marketing_login_clicked')
      window.location.assign(link)
    } else if(action == "signup"){
      link = SignUpLink
      if(window.fr){
        window.location.assign(FrLink)
      }
      if(window.trial){
        link+'?tr=1'
      }
      if(window.campaign){
        link+'?campaign='+window.campaign
      }
      await ReportFirebaseEvent('onboard_marketing_signup_clicked')
        window.location.assign(link)
    }
  }

  render() {
    let active = this.props.active;
    let logo = "/images/onmail-icon-logo-white.png";
    let logoDark = "/images/onmail-icon-logo-black.png";
    if(this.props.forcedHeaderImage){
      logo = this.props.forcedHeaderImage;
    }
    let anchorLink = '#hero';
    let buttonTitle = "Sign Up";
    let activeClass = "";
    let hideHeaderLinks = false;
    if(this.props.active){
      activeClass = "active";
    }
    let themeClass = "";
    let showOnScrollClass = "show-on-scroll";
    if(window.location.pathname != '/'){
      showOnScrollClass = "";
    }

    if(window.innerWidth < 800){
      return (
        <div className={"onmail-header-container edison-header-padding "+activeClass+" "+themeClass} style={{flexWrap:'wrap'}}>
          <div className="full-width" style={{minWidth:'100vw', textAlign:'center', backgroundColor:"rgba(0,0,0,.25)", padding:'12px', marginTop:"-14px", marginLeft:"-14px"}}>
            <a href="https://www.edison.tech/blog/edison-acquired-by-yipit" target="_blank">
              <p style={{color:"#fff", fontSize:"14px"}}>{`Announcement- Edison products are now joining the Yipit family! `}<span style={{marginLeft: "16px"}}><b>{`Read More`}</b></span></p>
            </a>
          </div>
          <OnmailMenu active={this.state.menuActive} toggleMenu={this.toggleMenu}/>
          <div className="row full-width middle">
            <div className="small-3 links-left row middle">

              <div className="padding-right-small">
                <Link to="/">
                  <img className="header-logo-white" alt="OnMail company logo in white" src={logo} />
                  <img className="header-logo-black" alt="OnMail company logo in black" style={{display: "none"}} src={logoDark} />
                </Link>
              </div>

            </div>
            <div className="small-9 row middle" style={{justifyContent: "flex-end"}}>
              {buttonTitle &&
                <div className={"sign-up-button "+showOnScrollClass}  onClick={()=>this.trackExit('signup')}>
                  <div className="button-onmail">{buttonTitle}</div>
                </div>
              }
              <div
                className="onmail-menu-icon margin-left-tiny"
                onClick={this.toggleMenu} style={{display: "inline-block"}}
              >
                <MaterialIcon
                  className="material-icons"
                  icon="menu"
                  color={OnmailColors.onmailWhite}
                />
              </div>
            </div>
          </div>
        </div>
      )
    } else{
      return (
        <div className={"onmail-header-container edison-header-padding "+activeClass+" "+themeClass} style={{flexWrap:'wrap'}}>
          <div className="full-width" style={{minWidth:'100vw', textAlign:'center', backgroundColor:"rgba(0,0,0,.25)", padding:'12px', marginTop:"-14px", marginLeft:"-14px"}}>
            <a href="https://www.edison.tech/blog/edison-acquired-by-yipit" target="_blank">
              <p style={{color:"#fff", fontSize:"14px"}}>{`Announcement- Edison products are now joining the Yipit family! `}<span style={{marginLeft: "16px"}}><b>{`Read More`}</b></span></p>
            </a>
          </div>
          <div className="row links-left"
          style={{alignItems: "center"}}>
            <Link className="" to="/">
              <img className="header-logo-white" src={logo} />
              <img className="header-logo-black" style={{display: "none"}} src={logoDark} />
            </Link>
            <Link className="header-link inline margin-left-small" to="/">
              <p>Home</p>
            </Link>
            <Link className="header-link inline margin-left-small" to="/vision">
              <p>Vision</p>
            </Link>
            <Link className="header-link inline margin-left-small" to="/team">
              <p>Our Team</p>
            </Link>
            <Link className="header-link inline margin-left-small" to="/experience">
              <p>Experience</p>
            </Link>
          </div>
          <div className="row links-right"
          style={{alignItems: "center"}}>
            <div className="inline margin-right-small">
              <div onClick={()=>this.trackExit('login')}>
                <div className="header-link">{"Log In"}</div>
              </div>
            </div>
            {buttonTitle &&
              <div className={"sign-up-button header-cta inline "+showOnScrollClass} style={{marginRight:"10px"}}>
                <div onClick={()=>this.trackExit('signup')}>
                  <div className="button-onmail">{buttonTitle}</div>
                </div>
              </div>
            }
          </div>
        </div>
      );
    }
  }
}

export default OnMailHeader;
