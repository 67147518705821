import ReactGA from 'react-ga';

export const ReportFirebaseEvent = async(name) =>{
  let dataCheck = await CanShareDataCheck();
  if(dataCheck){
    //await firebase.analytics().logEvent(name)
    //also send to goog
    await ReactGA.event({
      category: name,
      action: 'click'
    });
    if(
      name === "onboard_marketing_hero_button_clicked" ||
      name === "onboard_marketing_signup_clicked" ||
      name === "onboard_marketing_freelance_clicked" ||
      name === "onboard_marketing_professional_clicked" ||
      name === "onboard_marketing_business_clicked"
    ){
      //await firebase.analytics().logEvent('begin_checkout')

    }
  }
  return false
}

export const CanShareDataCheck = async () => {
  return true
}
