import React, { Component } from "react";
import HeroTitleText from './../../components/heroTitleText';
import TitleTextParagraph from './../../components/titleTextParagraph';
import {Helmet} from "react-helmet";

export default class ChatSecurity extends Component {
  render() {
    return(
      <div>
        <Helmet>
          <title>Chat Messages Security | Edison Mail | Edison Software</title>
          <meta name="description" content="Privacy and security come first at Edison Software" />
        </Helmet>
        <div className="small-padding primary-background centered-text">
          <img
            alt="Security lock surrounded by email and chat icons"
            className="full-width extra-large-top-margin"
            style={{maxWidth:"300px"}}
            src="/images/security-header.png"
          />
        </div>
        <div className="max-width-container">
          <div className="small-padding small-top-margin">
            <TitleTextParagraph
              title="Privacy & Security Come First at Edison"
              description="Safeguarding your privacy is always top of mind for us. That’s why we practice privacy by design in all new features we develop, like our new message ability. You trust us with your email, contacts, and other important information in the messages you exchange, and that’s not something we take lightly. We have built end-to-end encryption into the latest versions of our app, so that no matter what content your message holds, it stays between you and the recipient you choose."
            />
          </div>
          <div className="small-padding">
            <TitleTextParagraph
              title="End-To-End Encryption Built-In"
              description="Communicate while confident that your messages stay between you and your designated recipient. Edison Mail’s end-to-end encryption locks your messages securely, so that nobody but you and the person you reach out to can read and access the information you exchange--not even our team at Edison. Each message that you send is automatically encrypted with its own lock and key to ensure complete privacy and security at all times. There’s nothing you need to turn on or set up, the end-to-end encryption is always running behind the scenes to protect you."
            />
          </div>
          <div className="small-padding">
            <TitleTextParagraph
              title="Keep Calls Confidential"
              description="You can rely on Edison to ensure the calls you make through our app to your colleagues, friends, and family are also end-to-end encrypted. No matter where in the world the person you call is located, Edison’s backend technology keeps the conversation private and locked, so that unauthorized third parties can’t listen in."
            />
          </div>
        </div>
      </div>
      )
    };
  };
