import React, { Component } from 'react';
import {Colors} from './../../utils/constants';
import "./fullWidthImageText.scss";
import { withController, Parallax } from 'react-scroll-parallax';
import ResponsiveImage from './../responsiveImage';
import MaterialIcon from 'material-icons-react';


class FullWidthImageText extends Component {
  constructor(props) {
      super(props);
  }
  onImageLoad = () =>{
    this.props.parallaxController.update();
  }
  render() {
    let backgroundImage = this.props.backgroundImage;
    let backgroundColor = this.props.backgroundColor;
    let title = this.props.title;
    let description = this.props.description;
    let parallaxImage1 = this.props.parallaxImage1;
    let parallaxVideo1 = this.props.parallaxVideo1;
    let parallaxImage2 = this.props.parallaxImage2;
    let parallaxVideo2 = this.props.parallaxVideo2;
    let parallaxImage3 = this.props.parallaxImage3;
    let parallaxVideo3 = this.props.parallaxVideo3;
    let linktext = this.props.linktext;
    let link = this.props.link;
    let display = "block";
    let imageOrder = "";
    let specialPaddingClass = "left-full-width-padding";

    if(this.props.layout === "right"){
        imageOrder = "-1";
        specialPaddingClass = "right-full-width-padding";
    }
    if(this.props.linktext === undefined){
        display = "none";
    }


    return (
      <div className="full-width full-width-image-text" style={{"background-image": "url("+backgroundImage+")", backgroundColor: backgroundColor}}>
        <div className="max-width-container">
          <div className="row middle small-padding no-left-padding no-right-padding" style={{justifyContent: "center"}}>
            <div className={"small-12 medium-6 large-6 small-padding first-for-small center-for-small "+specialPaddingClass}>
              <h3>{title}</h3>
              <h5>{description}</h5>
              <div className="" style={{display: display}}>
                <a className="image-link row middle" href={link} target="_blank">
                  <h5>{linktext}</h5>
                  <div style={{position: "relative", left: "10px"}}>
                    <MaterialIcon
                      className="material-icons"
                      icon="arrow_forward"
                      color={Colors.primaryColor} />
                  </div>
                </a>
              </div>
            </div>
            <div className="small-12 medium-5 large-6" style={{order: imageOrder}}>
              <div className="full-width-parallax-layered-banner">
                <div className="parallax-image-background">
                  {parallaxImage1 && <ResponsiveImage className="full-width" onLoad={this.onImageLoad} src={parallaxImage1} />}
                  {parallaxVideo1 && <video playsInline autoPlay muted loop width="100%"><source src={this.props.parallaxVideo1} type="video/mp4" /></video>}
                </div>
                <div className="parallax-image-middleground">
                  {parallaxImage2 && <Parallax y={[-5, 5]}><ResponsiveImage className="full-width" onLoad={this.onImageLoad} src={parallaxImage2} /></Parallax>}
                  {parallaxVideo2 && <Parallax y={[-5, 5]}><video autoPlay muted loop width="100%"><source src={this.props.parallaxVideo2} type="video/mp4" /></video></Parallax>}
                </div>
                <div className="parallax-image-foreground">
                  {parallaxImage3 && <Parallax y={[-10, 10]}><ResponsiveImage className="full-width" onLoad={this.onImageLoad} src={parallaxImage3} /></Parallax>}
                  {parallaxVideo3 && <Parallax y={[-5, 5]}><video autoPlay muted loop width="100%"><source src={this.props.parallaxVideo3} type="video/mp4" /></video></Parallax>}
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    );
  }
}

export default withController(FullWidthImageText);
