import "./home.scss";
import React, { Component } from 'react';
import DefaultVariant from "./defaultVariant";
import Variant1 from "./variant1";
import Variant2 from "./variant2";
import Variant3 from "./variant3";
import Variant4 from "./variant4";
import {getValue } from "firebase/remote-config";
import { getAnalytics, logEvent } from "firebase/analytics";

export default class OnmailHome extends Component {
  constructor(props) {
      super(props);
      this.state ={};
  }
  componentDidMount = () =>{

  }

  trackLinkClick = (variant) =>{
    console.log(variant)
    const analytics = getAnalytics();
    logEvent(analytics, 'positioningExperimentClick', { value: variant});
    window.location.assign('https://mail.onmail.com/signup')
  }

  render() {
    let variant = null;
    let variantComponent = null
    if(this.props.remoteConfig){
       variant = getValue(this.props.remoteConfig,'onMailPositioningTest')
       console.log(variant)
       variant = variant._value
    }
    console.log(variant)
    const analytics = getAnalytics();
    if(variant == 1){
      variantComponent = <Variant1 trackLinkClick={this.trackLinkClick} />
      logEvent(analytics, 'positioningExperimentView', { value: '1'});
    }
    else if(variant == 2){
      variantComponent = <Variant2 trackLinkClick={this.trackLinkClick} />
      logEvent(analytics, 'positioningExperimentView', { value: '2'});
    }
    else if(variant == 3){
      variantComponent = <Variant3 trackLinkClick={this.trackLinkClick} />
      logEvent(analytics, 'positioningExperimentView', { value: '3'});
    }
    else if(variant == 4){
      variantComponent = <Variant4 trackLinkClick={this.trackLinkClick} />
      logEvent(analytics, 'positioningExperimentView', { value: '4'});
    } else{
      variantComponent = <DefaultVariant trackLinkClick={this.trackLinkClick} />
      logEvent(analytics, 'positioningExperimentView', { value: '0'});
    }

    return (
      <div className="full-width onmail-home">
        {variantComponent}
      </div>
    )
  }
}
//{variant == 0 && <DefaultVariant />}
//{variant == 1 && <Variant1 />}
//{variant == 2 && <Variant2 />}
//{variant == 3 && <Variant3 />}
//{variant == 4 && <Variant4 />}
