import React, { Component } from "react";
import {GetUrlVariable } from "./../../utils/functions";
import EmailTracking from "./emailTracking";
import WhyChat from "./whyChat";
import ChatSecurity from "./chatSecurity";
import PriceAlertSetupYahoo from "./priceAlertSetupYahoo";
import PriceAlertSetupGmail from "./priceAlertSetupGmail";
import PriceMatching from "./priceMatching";
import NotFound from "./notFound";
import { Link } from "react-router-dom";
import './mobileHelpers.scss';


export default class MobileHelpers extends Component {
  render() {
    // get revision number
    let content = <NotFound />
    let page = GetUrlVariable("page");
    if(page == "price-alert"){
      let type = GetUrlVariable("type");
      if(type == "yahoo"){
        content = <PriceAlertSetupYahoo />
      } else{
        content = <PriceAlertSetupGmail />
      }
    }
    else if(page == "whyChat"){
      content = <WhyChat />
    }
    else if(page == "chatSecurity"){
      content = <ChatSecurity />
    }
    else if(page == "priceMatching"){
      content = <PriceMatching />
    }
    else if(page == "emailTracking"){
      content = <EmailTracking />
    }

    return (
      <div className="mobile-helper-wrapper">
        {content}
      </div>
    );
  }
}
