import React, { Component } from 'react';
import './gradient-background.css';

export default class GradientBackground extends Component {
  render() {
    return (
      <div className="gradiento"></div>
    )
  }
}
