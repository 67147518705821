import React, { Component } from 'react';

export default class ResponsiveImage extends Component {
  constructor(props) {
      super(props);
      this.state = {}
  }
  componentDidMount = ()=>{
    this.tryResize();
    window.addEventListener("resize", this.tryResize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.tryResize);
  }
  tryResize = ()=>{
    let size = "full";
    let image = this.props.src;
    if(window.outerWidth > 600 && window.outerWidth < 1600){
      image = image.replace('.png','-medium.png').replace('.jpg','-medium.jpg');
      size = "medium";
    }
    if(window.outerWidth < 600){
      image = image.replace('.png','-small.png').replace('.jpg','-small.jpg');
      size = "small";
    }
    this.setState({
      image:image,
      size:size
    })
  }
  onError = () =>{
    console.log('error loading')
    if(this.state.size != "full"){
      this.setState({
        size:"full",
        image:this.props.src
      })
    } else{
      if(this.props.onError){
        this.props.onError();
      }
    }
  }
  onLoad = () =>{
    if(this.props.onLoad){
      this.props.onLoad();
    }
  }
  render() {
    if(!this.state.size) return null;
    return (
        <img {...this.props} src={this.state.image} onError={this.onError} onLoad={this.onLoad} />
    );
  }
}
