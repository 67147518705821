import React, { Component } from "react";
import HeroTitleText from './../../components/heroTitleText';
import TitleTextParagraph from './../../components/titleTextParagraph';
import {Helmet} from "react-helmet";

export default class EmailTracking extends Component {
  render() {
    return(
      <div>
        <Helmet>
          <title>Privacy | Edison Software</title>
          <meta name="description" content="A consumer technology company transforming how people communicate." />
        </Helmet>
        <div className="max-width-container">
          <HeroTitleText
            title1="We Take "
            highlight1="Privacy"
            title2="Seriously"
            //highlight2=" "
            //title3=" "
            //description=""
            alignment="left-text"
            //buttonTitle="Download"
            //ctaLink="https://developer.edison.tech"
          />
          <div className="section-padding">
            <div className="row middle">
                <div className="small-12 medium-5 large-5 small-padding">
                    <img
                      alt="Phone screenshot highlighting the email tracking blocked icon"
                      className="full-width"
                      style={{maxWidth:"600px", borderBottom: "2px solid #eee"}}
                      src="/images/privacy-shield-phone.png" />
                </div>
                <div className="small-12 medium-7 large-7 small-padding">
                  <TitleTextParagraph
                    title="What Is Email Tracking?"
                    description="Marketing and sales people use email tracking to secretly monitor the messages they send you. This is usually done be embedding a tiny, invisible image containing code somewhere in the message. Upon opening the message (or clicking links, etc.) a notification is logged and sent to the owner of the email."
                  />
                </div>
            </div>
            <div className="row middle">
                <div className="small-12 medium-5 large-5 small-padding">
                    <img
                      alt="Email rows showing hidden trackers in your email"
                      className="full-width"
                      style={{maxWidth:"600px"}}
                      src="/images/privacy-inbox.png" />
                </div>
                <div className="small-12 medium-7 large-7 small-padding">
                  <TitleTextParagraph
                    title="Why Should You Care?"
                    description="Have you ever had a salesman call you right after you've opened their email? Tracking emails allows senders to collect information about you to improve their tactics. Tracked emails can notify senders of exactly when you open their email, what you do with it, and sometimes even what you do online after you open it. They use this data to improve and better target their products to you."
                  />
                </div>
            </div>
            <div className="row middle">
                <div className="small-12 medium-5 large-5 small-padding">
                    <img
                      alt="Safe from trackers shield over all email account types"
                      className="full-width"
                      style={{maxWidth:"600px"}}
                      src="/images/privacy-emails.png" />
                </div>
                <div className="small-12 medium-7 large-7 small-padding">
                  <TitleTextParagraph
                    title="What We Do About It?"
                    description="We preemptively look for these trackers and block them before you open your message. When you see the green shield, you know you're protected. Just doing our job - giving you peace of mind by keeping your inbox private."
                  />
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
