import React, { Component } from "react";

export default class notFound extends Component {
  render() {
    return(
      <div className="max-width-container small-padding">
        {'Not found'}
      </div>
    );
  }
}
