import React, { Component } from "react";
import HeroTitleText from './../../components/heroTitleText';
import {GetUrlVariable} from "./../../utils/functions";
import TitleTextParagraph from './../../components/titleTextParagraph';
import {Helmet} from "react-helmet";

export default class PriceAlertSetupYahoo extends Component {
  render() {
    let email = GetUrlVariable("email");
    let originalEmail = GetUrlVariable("originalEmail");
    return(
      <div>
        <Helmet>
          <title>Price Alert Setup | Edison Mail | Edison Software</title>
          <meta name="description" content="Privacy and security come first at Edison Software" />
        </Helmet>
        <div className="max-width-container">
          <div className="full-width extra-large-top-margin centered-text">
            <img id="type-image"
              className="full-width"
              style={{maxWidth:"150px"}}
              src="/images/square.png"
            />
          </div>
          <HeroTitleText
            title1="Price Alert Setup for Edison Mail"
            //highlight1=""
            //title2="the Way We"
            //highlight2="Communicate"
            //title3=" "
            description="Follow the steps below to enable price alerts for your account. Sometimes network conditions or special circumstances require manual setup for the price alert feature."
            alignment="centered-text"
            //buttonTitle="Download"
            //ctaLink="https://developer.edison.tech"
          />
          <div className="small-padding centered-text">
    				<div id="yahoo">
              <div className="section-padding">
      					<h2>{"Step 1"}</h2>
      					<p>{"Go to"} <a target="_blank" href="https://mail.yahoo.com">{"Yahoo mail"}</a> {"and login with"} <b className="primary-color">{originalEmail}</b> {"on your desktop browser. (It's just easier)"}</p>
                <img className="full-width" src="/images/fwd-yahoo-1.png" />
              </div>
              <div className="">
      					<h2>{"Step 2"}</h2>
      					<p>{"Go to Settings > More Settings > Mailboxes and click the account"} <b className="primary-color">{originalEmail}</b></p>
                <img className="full-width" src="/images/fwd-yahoo-2.png" />
              </div>
              <div className="section-padding">
      					<h2>{"Step 3"}</h2>
      					<p>{"Enter "}<b className="primary-color">{email}</b> {"as the forwarding address and click the button titled 'Verify'."}</p>
                <img className="full-width" src="/images/fwd-yahoo-3.png" />
              </div>
              <div className="">
      					<h2>{"Step 4"}</h2>
      					<p>{"Send an email to"} <a href="mailto:mailsupport@edison.tech">{"mailsupport@edison.tech"}</a>{" from "}<b className="primary-color">{originalEmail}</b> {"to request your verification code to finish the setup."}</p>
                <img className="full-width" src="/images/fwd-yahoo-4.png" />
              </div>
              <div className="section-padding">
                <h2>{"Step 5"}</h2>
      					<p>{"Follow the verification link in your email and start saving money today."}</p>
                <img className="full-width" src="/images/fwd-yahoo-5.png" />
    	        </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
