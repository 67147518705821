import React, { Component } from 'react';
import "./fullWidthParallaxLayeredBanner.scss";
import { withController, Parallax } from 'react-scroll-parallax';
import ResponsiveImage from './../responsiveImage';

class FullWidthParallaxLayeredBanner extends Component {
  constructor(props) {
      super(props);
  }
  onImageLoad = () =>{
    this.props.parallaxController.update();
  }
  render() {
    let backgroundImage = this.props.backgroundImage;
    let foregroundImage = this.props.foregroundImage;
    let middlegroundImage = this.props.middlegroundImage;
    let ignoreResizeHackClass = "";
    if(this.props.ignoreResizeHack){
      ignoreResizeHackClass = 'ignore-resize-hack'
    }
    return (
      <div className={"full-width full-width-parallax-layered-banner "+ignoreResizeHackClass}>
        <div className="parallax-image-background">
          {backgroundImage && <ResponsiveImage className="full-width" onLoad={this.onImageLoad} src={backgroundImage} />}
        </div>
        <div className="parallax-image-middleground">
          {middlegroundImage && <Parallax y={[-2.5, 2.5]}><ResponsiveImage className="full-width" onLoad={this.onImageLoad} src={middlegroundImage} /></Parallax>}
        </div>
        <div className="parallax-image-foreground">
          {foregroundImage && <Parallax y={[-5, 5]}><ResponsiveImage className="full-width" onLoad={this.onImageLoad} src={foregroundImage} /></Parallax>}
        </div>
      </div>
    );
  }
}

export default withController(FullWidthParallaxLayeredBanner);
