import React, { Component } from "react";
import HeroTitleText from './../../components/heroTitleText';
import TitleTextParagraph from './../../components/titleTextParagraph';
import {Helmet} from "react-helmet";

export default class WhyChat extends Component {
  render() {
    return(
      <div>
        <Helmet>
          <title>Privacy | Edison Software</title>
          <meta name="description" content="A consumer technology company transforming how people communicate." />
        </Helmet>
        <div className="max-width-container">
          <HeroTitleText
            title1="Why Messages?"
            //highlight1=" "
            //title2=" "
            //highlight2=" "
            //title3=" "
            //description=""
            alignment="left-text"
            //buttonTitle=" "
            //ctaLink=" "
          />
          <div className="small-padding">
            <img
              alt=""
              className="full-width"
              style={{maxWidth:"400px"}}
              src="/images/why-chat.png"
            />
            <p className="subheading small-top-margin">Let's face it, sometimes composing an email requires a quick real-time conversation on the side first. A brief chat can be the shortcut you need to save your inbox from an unnecessary thread of emails. </p>
            <img
              alt=""
              className="full-width"
              style={{maxWidth:"400px"}}
              src="/images/why-chat-2.png"
            />
            <p className="subheading small-top-margin">That's why we integrated the ability to chat with your network, all without leaving the convenience of Edison Mail. The frequent need to switch between text, email, and phone calls to get the info you need is cumbersome.</p>
            <p className="subheading small-top-margin">The Edison Mail app with email + message + voice calls makes quickly confirming details before sending out a formal email seamless!</p>
          </div>
          <div className="small-padding">
            <TitleTextParagraph
              title="What about security?"
              description="Never worry about the privacy of your messages with end-to-end encryption – and with privacy control, you can block anyone you don’t want to chat with."
            />
          </div>
          <div className="small-padding">
            <TitleTextParagraph
              title="Who can I message?"
              description="Any email address you have in your Contacts is capable of receiving a message."
            />
          </div>
          <div className="small-padding">
            <TitleTextParagraph
              title="How do I invite someone to chat?"
              description="Simply compose a new message to any email address in your Contacts. The recipient will see the message in the Edison Mail app. If they aren't already an Edison Mail user, they will receive an email letting them know you sent them a message. They will see their message after they download the app (and connect the email address you sent the message to)."
            />
          </div>
          <div className="small-padding">
            <TitleTextParagraph
              title="Where does someone see a message I sent?"
              description="Double check they connected the email where you sent the message, and click on the Messages tab in the center of the bottom bar. All your messages will appear in this list."
            />
          </div>
        </div>
      </div>
    );
  }
}
