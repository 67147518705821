import React, { Component } from 'react';
import {GetUrlVariable} from './../../utils/functions';
import {Colors} from './../../utils/constants';
import Header from './../../components/header';
import {WebServerApiKey,WebServerRoot} from './../../utils/constants';
import {Link} from "react-router-dom";

export default class ShareHero extends Component {
  render() {
    return (
      <div className="full-height full-width">
        <div id="hero" className="row" style={{alignItems:"center", justifyContent:"center"}}>
          <div className="small-padding section-padding">
            <div className="max-width-container centered-text">
              <img style={{width:'100%', maxWidth:"600px"}} src="/images/oops.png" />
              {window.location.pathname != "/barkley" ?
              <span>
                <h4 className="full-width centered-text hero-text" style={{fontSize:"50px"}}>{'Ooops, This page does not exist'}</h4>
                <p className="subheading">{'Click the button below to go back home.'}</p>
              </span>
              :
              <span>
                <h4 className="full-width centered-text hero-text" style={{fontSize:"50px"}}>{'Woof'}</h4>
                <p className="subheading">{'Hi, I am Barkely and I like long walks on the beach and treats'}</p>
              </span>
              }
              <Link to="/" style={{marginTop:"40px"}} className="button primary-background white">{'Back to Home'}</Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
