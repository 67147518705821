import React, { Component } from 'react';
import VideoHero from "./../../../components/videoHero";
import GradientBackground from "./gradient-background";

export default class Hero extends Component {

  render() {
    console.log(this.props.link)
    return (
      <VideoHero
        video={this.props.video}
        tintOpacity=".01">
        <div className="row overlay home-video-overlay" style={{position:'relative', zIndex:2, paddingTop:100}}>
          <div className="row max-width-container" style={{justifyContent:'center'}}>
            <div className="small-12 medium-12 large-12 centered-text">
              {this.props.image && <img src={this.props.image} class="half-width" style={{maxWidth:800, margin:'auto'}} />}
            </div>
            <div className="small-12 medium-12 large-12 centered-text">
              <h1>{this.props.text}</h1>
              {this.props.subtitle && <h2>{this.props.subtitle}</h2>}
              <div className="row full-width full-height padding-top-small" style={{justifyContent: "center"}}>
                <div onClick={this.props.onButtonClick}>
                  <div className="button-onmail-large">{this.props.cta}</div>
                </div>
              </div>
            </div>

          </div>
        </div>
        {this.props.gradient && <GradientBackground />}
      </VideoHero>
    )
  }
}
