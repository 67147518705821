import React, { Component } from 'react';
import "./onmailMenu.scss";
import {Link, NavLink} from 'react-router-dom';
import {OnmailColors,LatestDownloadableOnmailAndroidLink,LatestDownloadableOnmailIosLink} from './../../utils/constants';

import MaterialIcon from 'material-icons-react';

class OnmailMenu extends Component {
  constructor(props) {
      super(props);
      this.state = {};
  }

  toggleMenu = () =>{
    this.setState({
      transitionActiveClass:true
    },()=>{
      setTimeout(()=>{
        this.props.toggleMenu()
        this.setState({
          transitionActiveClass:false
        })
      },500)
    })
  }

  render() {
    let logo = "/images/onmail-logo-white.png";
    let activeClass = "";
    let transitionActiveClass = "";
    if(this.props.active){
      activeClass = "active";
      transitionActiveClass = "animate-in";
    }
    if(this.state.transitionActiveClass){
      transitionActiveClass = "animate-out";
    }

    return (
      <div className={"onmail-menu-container "+activeClass}>
        <div className={"onmail-menu-container-transition-class "+transitionActiveClass}>
          <div className="onmail-menu">
            <div className="full-height padding-small padding-top-medium">
              <div
                className="onmail-close-icon"
                onClick={this.toggleMenu} style={{display: "inline-block"}}
              >
                <MaterialIcon
                  className="material-icons"
                  icon="close"
                  color={OnmailColors.onmailWhite}
                />
              </div>
              <span onClick={this.toggleMenu}>
                <Link className="white-text" to="/">
                  <h3 className="full-width padding-bottom-tiny header-link">
                    {'Home'}
                  </h3>
                </Link>
                <Link className="white-text" to="/vision">
                  <h3 className="full-width padding-top-tiny padding-bottom-tiny header-link">
                    {'Vision'}
                  </h3>
                </Link>
                <Link className="white-text" to="/team">
                  <h3 className="full-width padding-top-tiny padding-bottom-tiny header-link">
                    {'Our Team'}
                  </h3>
                </Link>
                <Link className="white-text" to="/experience">
                  <h3 className="full-width padding-top-tiny padding-bottom-tiny header-link">
                    {'Experience'}
                  </h3>
                </Link>
                <div>
                  <a className="" target="_blank" rel="noopener" href={LatestDownloadableOnmailAndroidLink}>
                    <img className="half-width inline padding-small" style={{maxWidth: "300px"}} src="/images/google-play-badge.svg" />
                  </a>
                  <a className="" target="_blank" rel="noopener" href={LatestDownloadableOnmailIosLink}>
                    <img className="half-width inline padding-small" style={{maxWidth: "300px"}} src="/images/app-store-badge.svg" />
                  </a>
                </div>
              </span>
              <div className="onmail-brand-stamp" style={{alignSelf: "flex-end"}}>
                <img className="full-width" src={logo} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OnmailMenu;
