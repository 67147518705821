import React, { Component } from 'react';
import "./textSeparator.scss";

export default class TextSeparator extends Component {
  constructor(props) {
      super(props);
  }
  render() {
    let title = this.props.title;
    let description = this.props.description;
    let subtitle = this.props.subtitle;

    return (
      <div className="text-separator" style={{maxWidth:"1400px"}}>
        {subtitle && <h7 className="subheading uppercase bold-text">{subtitle}</h7>}
        {title && <h2 className="massive-text">{title}</h2>}
        <div style={{maxWidth:"1000px"}}>
          {description && <p className="subheading">{description}</p>}
        </div>
      </div>
    );
  }
}
