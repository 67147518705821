import React, { Component, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { GetUrlVariable } from './utils/functions';
import { ReportFirebaseEvent } from './utils/analytics';
import {CanShareDataCheck} from './utils/analytics';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getRemoteConfig, fetchAndActivate, getValue} from "firebase/remote-config";
import Header from './components/header';
import Footer from './components/footer';
import OauthSuccess from './pages/oauthSuccess';

import Home from './pages/home';
import Vision from './pages/vision';
import Experience from './pages/experience';
import Team from './pages/team';
import Contact from './pages/contact';
import Pricing from './pages/pricing';
import GetStarted from './pages/getStarted';
import Connect from './pages/connect';
import Unsubscribe from './pages/unsubscribe';
import FourZeroFour from './pages/fourZeroFour';

import MobileHelpers from './pages/mobileHelpers';

export default class App extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount = async () => {

    let dataCheck = await CanShareDataCheck();
    if(dataCheck){
      const firebaseConfig = {
        apiKey: "AIzaSyA6hPNAcAaQfe9pSRmvo_Nmm2rpwP4sX6I",
        authDomain: "onmail-website.firebaseapp.com",
        projectId: "onmail-website",
        storageBucket: "onmail-website.appspot.com",
        messagingSenderId: "444624995689",
        appId: "1:444624995689:web:1428f8851a97260bb469f8",
        measurementId: "G-1L40B17X82"
      };
      const app = await initializeApp(firebaseConfig);
      const analytics = await getAnalytics(app);
      const remoteConfig = await getRemoteConfig(app);
      remoteConfig.settings.minimumFetchIntervalMillis = 10000;
      remoteConfig.defaultConfig = {};

      await fetchAndActivate(remoteConfig)
      setTimeout(()=>{
        let variant = getValue(remoteConfig,'onMailPositioningTest')
        console.log(variant)
        variant = variant._value
        console.log(variant)
      },5000)
      console.log(remoteConfig)
      this.setState({
        remoteConfig:remoteConfig,
        ready: true,
      },()=>{
        let container = document.getElementById('page-content');
        container.classList.add('active');
      });
    } else{
      this.setState({
        ready: true,
      },()=>{
        let container = document.getElementById('page-content');
        container.classList.add('active');
      });
    }
  };


  forceHeaderImage = (image) => {
    if (image) {
      this.setState({ forcedHeaderImage: image });
    } else {
      this.setState({ forcedHeaderImage: null });
    }
  };

  componentDidUpdate = async () => {
    let dataCheck = await CanShareDataCheck();
    let container = document.getElementById('page-content');
    console.log(container.classList)
    if (container) {
      container.classList.remove('active');
    }
    window.scrollTo(0, 0);
    setTimeout(() => {
      if (container) {
        container.classList.add('active');
      }
    }, 350);
    if(dataCheck){

    }
  }

  render() {
    if (!this.state.ready) return null;

    window.site = 'email';
    let header = true;
    if (GetUrlVariable('header') == 'false') {
      header = false;
    }
    window.site = 'onMail';
    document.body.className += ' onMail';

    return (
      <div>
      <Route
        history={this.props.history}
        onChange={this.onRouteChange}
        render={({ location }) => (
              <div>
              <Header />
              <div id="page-content" style={{height:"100%"}}>
                <Switch>
                    <Route exact path="/"  render={(props) => <Home remoteConfig={this.state.remoteConfig} />} />
                    <Route exact path="/mobileHelpers"  render={(props) => <MobileHelpers />} />
                    <Route exact path="/oauthSuccess"  render={(props) => <OauthSuccess />} />
                    <Route exact path="/onMailUnsubscribe"  render={(props) => <Unsubscribe />} />
                    <Route exact path="/vision" render={(props) => <Vision />} />
                    <Route exact path="/experience"  render={(props) => <Experience />} />
                    <Route exact path="/team"  render={(props) => <Team />} />

                    <Route exact path="/onmailcontact"  render={(props) => <Contact />} />
                    <Route exact path="/onmailPricing"  render={(props) => <Pricing />} />
                    <Route exact path="/getstarted"  render={(props) => <GetStarted />} />
                    <Route exact path="/connect"  render={(props) => <Connect />} />
                    <Route  render={(props) => <FourZeroFour />} />
                </Switch>
                <Footer />
              </div>

            </div>
          )}
        />
      </div>
    );
  }
}
