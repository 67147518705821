import React, { Component } from 'react';
import {GetNewsArticles} from './../../utils/functions';
import {Colors,LatestDownloadableMacFile,LatestDownloadableAndroidLink,LatestDownloadableIosLink} from './../../utils/constants';
import {Link} from 'react-router-dom';
import TrackedLink from './../../components/trackedLink';
import MaterialIcon from 'material-icons-react';
import {Helmet} from "react-helmet";
import ScrollFadeIn from './../../components/scrollFadeIn';
import Footer from "./../../components/footer";
import { withController, Parallax } from 'react-scroll-parallax';
import './contact.scss';


export default class OnmailContact extends Component {
  constructor(props) {
      super(props);
      this.state ={};
  }
  render() {
    return (
      <div className="full-width onmail-contact-page">
        <Helmet>
          <title>Contact Us | OnMail | by Edison Software</title>
          <meta name="description" content="OnMail by Edison Software. It's time to change email." />
          <meta name="google-site-verification" content="yZ638yt0GZu1rqnAT2klj_g5IAsyi_33FQ_4MuBnptI" />
          <meta name="p:domain_verify" content="0703ddff50b36ba64a2edc3507c449ad" />
          <meta name='B-verify' content='3e7503ca0cb04368d4a783b926f0d84b95e74293' />
          <meta name="google-site-verification" content="JE63RlPVGqntnNKUZH5NpH3JO5SK2wPadGPkJ0FaIrY" />
          <meta property="og:title" content="OnMail by Edison Software" />
          <meta property="og:description" content="It's time to change email. Choose from thousands of custom domains included free from us to make an email address that sets you apart. " />
          <meta property="og:image" content="/images/onmail-social-share-img.png" />
          <meta property="og:url" content="https://www.onmail.com" />
          <meta name="twitter:title" content="OnMail by Edison Software" />
          <meta name="twitter:description" content="It's time to change email. Choose from thousands of custom domains included free from us to make an email address that sets you apart." />
          <meta name="twitter:image" content="/images/onmail-social-share-img-contact.png" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="theme-color" content="#2C2C2C" />
          <link rel="icon" type="image/png" href="/images/onmail-favicon.png" />
        </Helmet>

        <div className="full-width medium-top-margin mobile-top-padding">
          <div className="max-width-container small-padding no-bottom-padding">
            <h3>{`Feel free to reach out`}</h3>
          </div>
        </div>

        <div className="row max-width-container">
          <div className="small-12 medium-6 small-padding description-padding">
            <p className="tiny-bottom-margin" style={{maxWidth: "500px"}}>{`Press? Questions about our company or our team?
Send us a quick note and we'll get back to you shortly.`}</p>
          </div>
          <div className="small-12 medium-6 tiny-padding">
            <form action="/">
              <div className="row">
                <div className="small-12 form-padding">
                  <label className="subheading" for="name">{"Name"}</label>
                  <input style={{marginTop: "8px"}} type="text" id="name" name="name" placeholder="Jane Doe" />
                </div>
                <div className="small-12 medium-6 form-padding">
                  <label className="subheading" for="email">{"Email"}</label>
                  <input style={{marginTop: "8px"}} type="text" id="email" name="email" placeholder="example@onmail.com" />
                </div>
                <div className="small-12 medium-6 form-padding">
                  <label className="subheading" for="phone">{"Phone"}</label>
                  <input style={{marginTop: "8px"}} type="text" id="phone" name="phone" placeholder="123-456-7890" />
                </div>
                <div className="small-12 form-padding">
                  <label className="subheading" for="message">{"Message"}</label>
                  <textarea style={{marginTop: "8px"}} type="text" id="message" name="message" rows="5" placeholder="Start typing..." />
                </div>
              </div>
              <div className="form-padding">
                <div className="button primary-background white" value="Send">{"Send"}</div>
              </div>
            </form>

          </div>
        </div>
        <div style={{paddingTop: "100px"}}>
          <Footer/>
        </div>
      </div>
    )
  }
}
