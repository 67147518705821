import React, { Component } from 'react';
import "./onMailPopUpFrame.scss";

export default class OnMialPopUpFrame extends Component {
  constructor(props) {
      super(props);
      this.state = {};
  }
  render() {
    let title = this.props.title;
    let description = this.props.description;
    let image = this.props.image;
    let maxWidth = this.props.maxwidth;

    if(this.props.maxwidth == undefined){
        maxWidth = "max-width-container"
    }

    return (
      <div className={'onmail-pop-up-frame'}>
        <div className="onmail-pop-up-frame-header background-image-fill" style={{backgroundImage:'url('+image+')'}}>
          <div className="full-width full-height overlay">
          </div>
        </div>
        <div className={"onmail-pop-up-frame-content "+maxWidth}>
          <div className="small-12 medium-12 large-12 padding-small padding-bottom-large">
            {this.props.title && <h1 className="onmail-pop-up-frame-title white-text">{title}</h1>}
            {this.props.description && <h4 className="onmail-pop-up-frame-description regular-text white-text">{description}</h4>}
          </div>
          <div className={"onmail-popup-frame-article shadow padding-small"}>
            {this.props.children && this.props.children}
          </div>
        </div>

      </div>
    );
  }
}
