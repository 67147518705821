import React, { Component } from 'react';
export default class LargeStatement extends Component {
  render() {
    return (
      <div className="home-statement section-padding margin-top-large">
        <div className="row max-width-container" style={{alignItems:"center", justifyContent:"center"}}>
          <div className="small-12 medium-10 large-8 padding-small centered-text onmail-dark-grey">
            <h2>{this.props.text}</h2>
          </div>
        </div>
      </div>
    )
  }
}
