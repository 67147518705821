import React, { Component } from 'react';
import "./onmailFullWidthImageText.scss";
import {Link} from 'react-router-dom';
import TrackedLink from './../../components/trackedLink';

export default class OnmailFullWidthImageText extends Component {
  constructor(props) {
      super(props);
  }
  render() {
    let title = this.props.title;
    let description = this.props.description;
    let buttonTitle = this.props.buttonTitle;
    let buttonLink = this.props.buttonLink;
    let backgroundImage = this.props.backgroundImage;
    let alignment = this.props.alignment == "centered-text" ? "centered-text" : "left-text";

    return (
      <div className="onmail-full-width-image-text">
        <div
          className="row"
          style={{backgroundImage: "url("+backgroundImage+")", backgroundSize: "cover", backgroundPosition: "center"}}>
          <div
            className={"row full-width full-height overlay "+alignment}
            style={{alignItems:"center", justifyContent:"center", position:"relative"}}>
            <div className="max-width-container row"
              style={{justifyContent:"center"}}>
              <div className="small-12 medium-10 large-8 padding-small">
                <h2 className="white-text">{title}</h2>
                {description && <p class="full-width margin-top-tiny">{description}</p>}
                {buttonLink && <a className="button-onmail margin-top-small" target="_blank" href={buttonLink}>{buttonTitle}</a>}
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}
